<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{padding:'0'}">
      <div slot="header">
        <span class="iconfont icon-MyAccounts">&nbsp;我的账务</span>
      </div>
      <div style="padding:20px">
        <el-row :gutter="50">
          <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
            <div class="account_first">
              <img src="../../assets/accounting/account_1.png" width="60" height="60" />

              <span class="account_first_content">
                场所数量
                <span class="account_first_content_number">
                  <countTo :startVal="0" :endVal="overview.groupCount"></countTo>
                </span>
                个
              </span>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
            <div class="account_second">
              <img src="../../assets/accounting/account_2.png" width="60" height="60" />
              <span class="account_first_content">
                终端数量
                <span class="account_first_content_number">
                  <countTo :startVal="0" :endVal="overview.clientCount"></countTo>
                </span>
                个
              </span>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
            <div class="account_third">
              <img src="../../assets/accounting/account_3.png" width="60" height="60" />
              <span class="account_first_content">
                账户余额
                <span class="account_first_content_number">
                  <countTo :startVal="0" :endVal="userInfo.balance"></countTo>
                </span>元
              </span>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :sm="24" :xs="24" :offset="0" style="margin:5px 0">
            <div class="account_fourth">
              <img src="../../assets/accounting/account_4.png" width="60" height="60" />
              <span class="account_first_content">
                当月消费
                <span class="account_first_content_number">
                  <countTo :startVal="0" :endVal="overview.consumption?overview.consumption:0"></countTo>
                </span>元
              </span>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card shadow="never" style="margin-top:20px">
      <div slot="header">
        <span>账务明细</span>
      </div>
      <div class="account_search_view">
        <el-date-picker
          v-model="searchTime"
          placeholder="选择开始日期"
          value-format="yyyy-MM-dd"
          size="small"
          @change="initLoad"
        ></el-date-picker>
      </div>
      <el-table
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        style="width: 100%"
        size="mini"
      >
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="detail" label="资金明细"></el-table-column>
        <el-table-column prop="description" label="说明"></el-table-column>
        <el-table-column prop="time" label="订单时间"></el-table-column>
        <el-table-column prop="type" label="支付方式">
          <template slot-scope="scope">
            <span v-if="scope.row.type=='wechat'">微信</span>
            <span v-if="scope.row.type=='alipay'">支付宝</span>
            <span v-if="scope.row.type=='balance'">余额</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getAccountsApi, getCountByUserApi } from "../../api/data";
import Aes from "../../utils/aes";
import countTo from "vue-count-to";
export default {
  components: {
    countTo
  },
  data() {
    return {
      list: [],
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      searchTime: "",
      overview: {},
      loading: true
    };
  },
  computed: {
    //登录账户信息
    userInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.userInfo));
    }
  },
  created() {
    this.initLoad();
    this.initCount();
  },
  methods: {
    initLoad() {
      getAccountsApi({ time: this.searchTime }).then(res => {
        this.loading = false;
        this.list = res.data;
      });
    },
    //查询账号下场所 终端数量
    initCount() {
      getCountByUserApi().then(res => {
        this.overview = res.data;
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.account_first {
  background: #ffeddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  .account_first_content {
    margin-left: 50px;
    .account_first_content_number {
      padding: 0 10px;
      font-size: 40px;
      color: #ffa155;
    }
  }
}
.account_second {
  background: #dff2ff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  .account_first_content {
    margin-left: 50px;
    .account_first_content_number {
      padding: 0 10px;
      font-size: 40px;
      color: #59bfff;
    }
  }
}
.account_third {
  background: #e5f9dd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  .account_first_content {
    margin-left: 50px;
    .account_first_content_number {
      padding: 0 10px;
      font-size: 40px;
      color: #84dfaa;
    }
  }
}
.account_fourth {
  background: #fae8ff;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  .account_first_content {
    margin-left: 50px;
    .account_first_content_number {
      padding: 0 10px;
      font-size: 40px;
      color: #d781ff;
    }
  }
}
.account_search_view {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
